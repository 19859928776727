import { useHttpErrorHandler } from "~/composables/useHttpErrorHandler";
import { useThrottleFn } from "@vueuse/core";

class Api {
  constructor(host = null, strategy = "cms") {
    const config = useRuntimeConfig();

    this.baseApi = host ?? config.public.apiBase;
    this.strategy = strategy;
    this.basicAuthToken =
      typeof btoa !== "undefined" ? btoa(`${config.public.basicName}:${config.public.basicPass}`) : "";
    this.baseHeaders = {};
    this.notificationStore = useNotificationStore();
    this.userStore = useUserStore();
    this.authStrategies = {
      cms: {
        access: () => {
          return { jwt: `Bearer ${this.userStore.GET_ACCESS_TOKEN}` };
        },
        refresh: () => {
          return { refreshToken: this.userStore.GET_REFRESH_TOKEN };
        },
        basic: () => {
          return { authorization: `Basic ${this.basicAuthToken}` };
        },
      },
      api: {
        access: () => {
          return config.public.BASIC_AUTH === "true"
            ? {
                "X-Auth-Token": `Bearer ${this.userStore.GET_ACCESS_TOKEN}`,
              }
            : {
                Authorization: `Bearer ${this.userStore.GET_ACCESS_TOKEN}`,
              };
        },
        refresh: () => {
          return { refreshToken: this.userStore.GET_REFRESH_TOKEN };
        },
      },
    };
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   */
  get(path, options) {
    return this.request(`${this.baseApi}${path}`, {
      ...options,
      ...this.baseHeaders,
      method: "GET",
    });
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   */
  post(path, options = {}) {
    return this.request(`${this.baseApi}${path}`, {
      ...options,
      ...this.baseHeaders,
      method: "POST",
      body: options.body,
      params: options.params,
    });
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   */
  patch(path, options) {
    return this.request(`${this.baseApi}${path}`, {
      ...options,
      ...this.baseHeaders,
      method: "PATCH",
    });
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   */
  put(path, options) {
    return this.request(`${this.baseApi}${path}`, {
      ...options,
      ...this.baseHeaders,
      method: "PUT",
    });
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   * @returns {import('nuxt/app').AsyncData}
   */
  delete(path, options) {
    return this.request(`${this.baseApi}${path}`, {
      ...options,
      ...this.baseHeaders,
      method: "delete",
    });
  }

  /**
   *
   * @param {Path} path
   * @param {Options} options
   */
  request(path, options) {
    return useLazyFetch(path, {
      ...options,

      onRequest: ({ options }) => {
        if (this.userStore.GET_ACCESS_TOKEN) {
          options.headers = {
            ...(this.authStrategies[this.strategy]?.access() ?? {}),
            ...(this.authStrategies[this.strategy]?.basic?.() ?? {}),
            ...options.headers,
          };
        }

        if (options.convertToFormData) {
          options.body = objectToFormData(options.body);
        }
      },

      onResponse: async ({ response }) => {
        if (process.server) return;

        if (options.disableNotification) return;

        const statusCode = response._data?.statusCode || response.status;

        // TODO we need to standardized ERROR EXCEPTIONS on Back-End side
        const errorCode =
          response?._data?.data?.errorCode ||
          response?._data?.data?.details?.errorCode ||
          response._data?.details?.errorCode ||
          response._data?.errorCode;

        // = = = = = = = = = = = = handling ERRORS  = = = = = = = = =

        await useHttpErrorHandler({
          errorCode,
          statusCode,
          response,
          notificationStore: this.notificationStore,
          refreshCallback: this.refreshSession,
          endCallback: this.endUserSession,
        });
      },
    });
  }

  endUserSession = useThrottleFn(async () => {
    await useFetch("/api/auth/sign-out", {
      headers: {
        ...(this.authStrategies.api?.access() ?? {}),
      },
      method: "POST",
    });

    this.notificationStore.ADD({
      title: "Your Log-In session has expired",
    });

    return this.userStore.LOGOUT();
  }, 1900);

  refreshSession = useThrottleFn(async () => {
    const { data } = await useFetch("/api/auth/refresh", {
      dedupe: "defer",
      headers: {
        ...(this.authStrategies.api?.access() ?? {}),
        refreshToken: this.userStore.GET_REFRESH_TOKEN,
      },
      method: "POST",
    });

    if (data.value?.statusCode === 401) {
      return this.endUserSession();
    }

    this.userStore.REFRESH_SESSION({
      accessToken: data.value.accessToken,
      refreshToken: data.value.refreshToken,
    });

    const to = useRoute().name;

    return useRouter().push(to !== "home" ? { name: "home" } : { name: "explore" });
  }, 1500);
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      nft: new Api(useRuntimeConfig().public.nftBase, "nft"),
      api: new Api(`${process.server ? useRuntimeConfig().public.domain : ""}/api/`, "api"),
    },
  };
});

/**
 * @typedef {Object} CustomOptions
 * @property {boolean} [convertToFormData] - Convert body to FormData
 *
 * @typedef {import('nuxt/app').UseFetchOptions & CustomOptions} Options
 * @typedef {`${string}/${string}`} Path
 */
