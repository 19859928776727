import type { RouterConfig } from "@nuxt/schema";

const getDescription = (name: string) =>
  `Discover, Collect and Trade Unique NFTs Assets in the CEEK Metaverse - ${name} - nft.ceek.com`;

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (_routes) => [
    {
      name: "sign-in",
      path: "/sign-in/:token?/:email?/",
      meta: {
        layout: "auth",
        description: getDescription("Log in"),
      },
      component: () => import("~/pages/sign-in.vue"),
    },
    {
      name: "update-user-info",
      path: "/update-user-info/",
      meta: {
        layout: "auth",
      },
      component: () => import("~/pages/update-user-info.vue"),
    },
    {
      name: "sign-up",
      path: "/sign-up/",
      meta: {
        layout: "auth",
        description: getDescription("Sign Up"),
      },
      component: () => import("~/pages/sign-up.vue"),
    },
    {
      name: "forgot-password",
      path: "/forgot-password/",
      meta: {
        layout: "auth",
        description: getDescription("Forgot password"),
      },
      component: () => import("~/pages/forgot-password.vue"),
    },
    {
      name: "home",
      path: "/",
      meta: {
        layout: "default",
        description: getDescription("Home"),
      },
      component: () => import("~/pages/index.vue"),
    },
    {
      name: "explore",
      path: "/explore/",
      meta: {
        layout: "default",
        description: getDescription("Explore"),
      },
      component: () => import("~/pages/explore.vue"),
    },
    {
      name: "details",
      path: "/details/:id",
      props: true,
      meta: {
        layout: "default",
      },
      component: () => import("~/pages/details.vue"),
    },
    {
      name: "main-utilities",
      path: "/utilities",
      meta: {
        layout: "default",
        description: getDescription("Utilities"),
      },
      component: () => import("~/pages/utilities.vue"),
    },
    {
      name: "collection",
      path: "/collection/:id",
      meta: {
        layout: "default",
      },
      component: () => import("~/pages/collection.vue"),
    },
    {
      name: "user-collection",
      path: "/user-collection/:id",
      meta: {
        layout: "default",
      },
      component: () => import("~/pages/user-collection.vue"),
    },
    {
      name: "support",
      path: "/support/",
      meta: {
        layout: "default",
        description: getDescription("Support"),
      },
      component: () => import("~/pages/support.vue"),
    },
    {
      name: "userguide",
      path: "/userguide/",
      meta: {
        layout: "default",
        description: getDescription("User Guide"),
      },
      component: () => import("~/pages/userguide.vue"),
    },
    {
      name: "terms",
      path: "/terms/",
      meta: {
        layout: "default",
        description: getDescription("Terms"),
      },
      component: () => import("~/pages/terms.vue"),
    },
    {
      name: "create",
      path: "/create/",
      meta: {
        middleware: ["auth"],
        layout: "default",
        description: getDescription("Create"),
      },
      component: () => import("~/pages/create.vue"),
    },
    {
      name: "my-items",
      path: "/my-items/",
      meta: {
        middleware: ["auth"],
        layout: "default",
        description: getDescription("My Items"),
      },
      component: () => import("~/pages/my-items.vue"),
    },
    {
      name: "profile",
      path: "/profile/",
      meta: {
        middleware: ["auth"],
        layout: "default",
        description: getDescription("My Profile"),
      },
      component: () => import("~/pages/profile.vue"),
    },
    {
      name: "transactions",
      path: "/transactions/",
      meta: {
        middleware: ["auth"],
        layout: "default",
        description: getDescription("Transactions"),
      },
      component: () => import("~/pages/transactions.vue"),
    },
    {
      name: "cms",
      path: "/cms",
      meta: {
        middleware: ["cms"],
        layout: "cms",
      },
      // component: () => import("~/pages/cms.vue"),
      redirect: "/cms/marketplace",

      children: [
        {
          name: "marketplace",
          path: "marketplace/",
          component: () => import("~/pages/cms/index.vue"),
        },
        {
          name: "users-wallets",
          path: "users-wallets/",
          component: () => import("~/pages/cms/users-wallets.vue"),
        },
        {
          name: "collections",
          path: "collections/",
          component: () => import("~/pages/cms/collections.vue"),
        },
        {
          name: "terms-and-conditions",
          path: "terms-and-conditions/",
          component: () => import("~/pages/cms/terms-and-conditions.vue"),
        },
        {
          name: "user-guide",
          path: "user-guide/",
          component: () => import("~/pages/cms/terms-and-conditions.vue"),
        },
        {
          name: "hero-section",
          path: "hero-section/",
          component: () => import("~/pages/cms/hero-section.vue"),
        },
        {
          name: "minters",
          path: "minters/",
          component: () => import("~/pages/cms/minters.vue"),
        },
        {
          name: "featured",
          path: "featured/",
          component: () => import("~/pages/cms/featured.vue"),
        },
        {
          name: "utilities",
          path: "utilities/",
          component: () => import("~/pages/cms/utilities.vue"),
        },
        {
          name: "settings",
          path: "settings/",
          component: () => import("~/pages/cms/settings.vue"),
        },
        {
          name: "nfts",
          path: "nfts/",
          component: () => import("~/pages/cms/nfts.vue"),
        },
      ],
    },
    {
      name: "user-custom-url",
      path: "/:customUrl",
      meta: {
        layout: "default",
      },
      component: () => import("~/pages/user-collection.vue"),
    },
    {
      name: "not-found",
      path: "/:pathMatch(.*)",
      meta: {
        layout: "default",
      },
      component: () => import("~/pages/system/404.vue"),
    },
  ],
};
