import { default as collectionsJIpa2VmBC8Meta } from "/opt/buildhome/repo/pages/cms/collections.vue?macro=true";
import { default as featuredCn0nxTOOH1Meta } from "/opt/buildhome/repo/pages/cms/featured.vue?macro=true";
import { default as hero_45sectiondbzNvdDxLiMeta } from "/opt/buildhome/repo/pages/cms/hero-section.vue?macro=true";
import { default as indexMpTnfPceivMeta } from "/opt/buildhome/repo/pages/cms/index.vue?macro=true";
import { default as mintersYOpXr768BWMeta } from "/opt/buildhome/repo/pages/cms/minters.vue?macro=true";
import { default as nftsIPHcFFRfHwMeta } from "/opt/buildhome/repo/pages/cms/nfts.vue?macro=true";
import { default as settingslnsHDYtxDpMeta } from "/opt/buildhome/repo/pages/cms/settings.vue?macro=true";
import { default as terms_45and_45conditionsIrYqKGZzNAMeta } from "/opt/buildhome/repo/pages/cms/terms-and-conditions.vue?macro=true";
import { default as users_45walletsR6Q7zcP5LeMeta } from "/opt/buildhome/repo/pages/cms/users-wallets.vue?macro=true";
import { default as utilitiesjmuK4QbdSQMeta } from "/opt/buildhome/repo/pages/cms/utilities.vue?macro=true";
import { default as cmsnebqYyvpSOMeta } from "/opt/buildhome/repo/pages/cms.vue?macro=true";
import { default as collectionFmGmuXPl5pMeta } from "/opt/buildhome/repo/pages/collection.vue?macro=true";
import { default as createHZN0PlvGuPMeta } from "/opt/buildhome/repo/pages/create.vue?macro=true";
import { default as details2tkI4aBAV9Meta } from "/opt/buildhome/repo/pages/details.vue?macro=true";
import { default as explorerYI361piByMeta } from "/opt/buildhome/repo/pages/explore.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as my_45itemspYrSrwOQ5DMeta } from "/opt/buildhome/repo/pages/my-items.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as sign_45incExpy2WlpQMeta } from "/opt/buildhome/repo/pages/sign-in.vue?macro=true";
import { default as sign_45upDLoukKzOegMeta } from "/opt/buildhome/repo/pages/sign-up.vue?macro=true";
import { default as supportut1ncBHFJHMeta } from "/opt/buildhome/repo/pages/support.vue?macro=true";
import { default as _404mrcaUJxpX1Meta } from "/opt/buildhome/repo/pages/system/404.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as transactionsyEtwEt0f5lMeta } from "/opt/buildhome/repo/pages/transactions.vue?macro=true";
import { default as update_45user_45infobNGFRxvuGcMeta } from "/opt/buildhome/repo/pages/update-user-info.vue?macro=true";
import { default as user_45collectionZjH2tkquQDMeta } from "/opt/buildhome/repo/pages/user-collection.vue?macro=true";
import { default as userguidekh3RpuDimaMeta } from "/opt/buildhome/repo/pages/userguide.vue?macro=true";
import { default as utilitiesBXIgi23fvKMeta } from "/opt/buildhome/repo/pages/utilities.vue?macro=true";
export default [
  {
    path: cmsnebqYyvpSOMeta?.path ?? "/cms",
    children: [
  {
    name: collectionsJIpa2VmBC8Meta?.name ?? "cms-collections",
    path: collectionsJIpa2VmBC8Meta?.path ?? "collections",
    meta: collectionsJIpa2VmBC8Meta || {},
    alias: collectionsJIpa2VmBC8Meta?.alias || [],
    redirect: collectionsJIpa2VmBC8Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/collections.vue").then(m => m.default || m)
  },
  {
    name: featuredCn0nxTOOH1Meta?.name ?? "cms-featured",
    path: featuredCn0nxTOOH1Meta?.path ?? "featured",
    meta: featuredCn0nxTOOH1Meta || {},
    alias: featuredCn0nxTOOH1Meta?.alias || [],
    redirect: featuredCn0nxTOOH1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/featured.vue").then(m => m.default || m)
  },
  {
    name: hero_45sectiondbzNvdDxLiMeta?.name ?? "cms-hero-section",
    path: hero_45sectiondbzNvdDxLiMeta?.path ?? "hero-section",
    meta: hero_45sectiondbzNvdDxLiMeta || {},
    alias: hero_45sectiondbzNvdDxLiMeta?.alias || [],
    redirect: hero_45sectiondbzNvdDxLiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/hero-section.vue").then(m => m.default || m)
  },
  {
    name: indexMpTnfPceivMeta?.name ?? "cms",
    path: indexMpTnfPceivMeta?.path ?? "",
    meta: indexMpTnfPceivMeta || {},
    alias: indexMpTnfPceivMeta?.alias || [],
    redirect: indexMpTnfPceivMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/index.vue").then(m => m.default || m)
  },
  {
    name: mintersYOpXr768BWMeta?.name ?? "cms-minters",
    path: mintersYOpXr768BWMeta?.path ?? "minters",
    meta: mintersYOpXr768BWMeta || {},
    alias: mintersYOpXr768BWMeta?.alias || [],
    redirect: mintersYOpXr768BWMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/minters.vue").then(m => m.default || m)
  },
  {
    name: nftsIPHcFFRfHwMeta?.name ?? "cms-nfts",
    path: nftsIPHcFFRfHwMeta?.path ?? "nfts",
    meta: nftsIPHcFFRfHwMeta || {},
    alias: nftsIPHcFFRfHwMeta?.alias || [],
    redirect: nftsIPHcFFRfHwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/nfts.vue").then(m => m.default || m)
  },
  {
    name: settingslnsHDYtxDpMeta?.name ?? "cms-settings",
    path: settingslnsHDYtxDpMeta?.path ?? "settings",
    meta: settingslnsHDYtxDpMeta || {},
    alias: settingslnsHDYtxDpMeta?.alias || [],
    redirect: settingslnsHDYtxDpMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/settings.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsIrYqKGZzNAMeta?.name ?? "cms-terms-and-conditions",
    path: terms_45and_45conditionsIrYqKGZzNAMeta?.path ?? "terms-and-conditions",
    meta: terms_45and_45conditionsIrYqKGZzNAMeta || {},
    alias: terms_45and_45conditionsIrYqKGZzNAMeta?.alias || [],
    redirect: terms_45and_45conditionsIrYqKGZzNAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: users_45walletsR6Q7zcP5LeMeta?.name ?? "cms-users-wallets",
    path: users_45walletsR6Q7zcP5LeMeta?.path ?? "users-wallets",
    meta: users_45walletsR6Q7zcP5LeMeta || {},
    alias: users_45walletsR6Q7zcP5LeMeta?.alias || [],
    redirect: users_45walletsR6Q7zcP5LeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/users-wallets.vue").then(m => m.default || m)
  },
  {
    name: utilitiesjmuK4QbdSQMeta?.name ?? "cms-utilities",
    path: utilitiesjmuK4QbdSQMeta?.path ?? "utilities",
    meta: utilitiesjmuK4QbdSQMeta || {},
    alias: utilitiesjmuK4QbdSQMeta?.alias || [],
    redirect: utilitiesjmuK4QbdSQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms/utilities.vue").then(m => m.default || m)
  }
],
    name: cmsnebqYyvpSOMeta?.name ?? undefined,
    meta: cmsnebqYyvpSOMeta || {},
    alias: cmsnebqYyvpSOMeta?.alias || [],
    redirect: cmsnebqYyvpSOMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cms.vue").then(m => m.default || m)
  },
  {
    name: collectionFmGmuXPl5pMeta?.name ?? "collection",
    path: collectionFmGmuXPl5pMeta?.path ?? "/collection",
    meta: collectionFmGmuXPl5pMeta || {},
    alias: collectionFmGmuXPl5pMeta?.alias || [],
    redirect: collectionFmGmuXPl5pMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/collection.vue").then(m => m.default || m)
  },
  {
    name: createHZN0PlvGuPMeta?.name ?? "create",
    path: createHZN0PlvGuPMeta?.path ?? "/create",
    meta: createHZN0PlvGuPMeta || {},
    alias: createHZN0PlvGuPMeta?.alias || [],
    redirect: createHZN0PlvGuPMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/create.vue").then(m => m.default || m)
  },
  {
    name: details2tkI4aBAV9Meta?.name ?? "details",
    path: details2tkI4aBAV9Meta?.path ?? "/details",
    meta: details2tkI4aBAV9Meta || {},
    alias: details2tkI4aBAV9Meta?.alias || [],
    redirect: details2tkI4aBAV9Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/details.vue").then(m => m.default || m)
  },
  {
    name: explorerYI361piByMeta?.name ?? "explore",
    path: explorerYI361piByMeta?.path ?? "/explore",
    meta: explorerYI361piByMeta || {},
    alias: explorerYI361piByMeta?.alias || [],
    redirect: explorerYI361piByMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordcBWiw0xyOQMeta?.name ?? "forgot-password",
    path: forgot_45passwordcBWiw0xyOQMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    alias: forgot_45passwordcBWiw0xyOQMeta?.alias || [],
    redirect: forgot_45passwordcBWiw0xyOQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: my_45itemspYrSrwOQ5DMeta?.name ?? "my-items",
    path: my_45itemspYrSrwOQ5DMeta?.path ?? "/my-items",
    meta: my_45itemspYrSrwOQ5DMeta || {},
    alias: my_45itemspYrSrwOQ5DMeta?.alias || [],
    redirect: my_45itemspYrSrwOQ5DMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/my-items.vue").then(m => m.default || m)
  },
  {
    name: profileg2OzvUzQjKMeta?.name ?? "profile",
    path: profileg2OzvUzQjKMeta?.path ?? "/profile",
    meta: profileg2OzvUzQjKMeta || {},
    alias: profileg2OzvUzQjKMeta?.alias || [],
    redirect: profileg2OzvUzQjKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: sign_45incExpy2WlpQMeta?.name ?? "sign-in",
    path: sign_45incExpy2WlpQMeta?.path ?? "/sign-in",
    meta: sign_45incExpy2WlpQMeta || {},
    alias: sign_45incExpy2WlpQMeta?.alias || [],
    redirect: sign_45incExpy2WlpQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upDLoukKzOegMeta?.name ?? "sign-up",
    path: sign_45upDLoukKzOegMeta?.path ?? "/sign-up",
    meta: sign_45upDLoukKzOegMeta || {},
    alias: sign_45upDLoukKzOegMeta?.alias || [],
    redirect: sign_45upDLoukKzOegMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: supportut1ncBHFJHMeta?.name ?? "support",
    path: supportut1ncBHFJHMeta?.path ?? "/support",
    meta: supportut1ncBHFJHMeta || {},
    alias: supportut1ncBHFJHMeta?.alias || [],
    redirect: supportut1ncBHFJHMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/support.vue").then(m => m.default || m)
  },
  {
    name: _404mrcaUJxpX1Meta?.name ?? "system-404",
    path: _404mrcaUJxpX1Meta?.path ?? "/system/404",
    meta: _404mrcaUJxpX1Meta || {},
    alias: _404mrcaUJxpX1Meta?.alias || [],
    redirect: _404mrcaUJxpX1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/system/404.vue").then(m => m.default || m)
  },
  {
    name: termsjw1825O2ZdMeta?.name ?? "terms",
    path: termsjw1825O2ZdMeta?.path ?? "/terms",
    meta: termsjw1825O2ZdMeta || {},
    alias: termsjw1825O2ZdMeta?.alias || [],
    redirect: termsjw1825O2ZdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: transactionsyEtwEt0f5lMeta?.name ?? "transactions",
    path: transactionsyEtwEt0f5lMeta?.path ?? "/transactions",
    meta: transactionsyEtwEt0f5lMeta || {},
    alias: transactionsyEtwEt0f5lMeta?.alias || [],
    redirect: transactionsyEtwEt0f5lMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/transactions.vue").then(m => m.default || m)
  },
  {
    name: update_45user_45infobNGFRxvuGcMeta?.name ?? "update-user-info",
    path: update_45user_45infobNGFRxvuGcMeta?.path ?? "/update-user-info",
    meta: update_45user_45infobNGFRxvuGcMeta || {},
    alias: update_45user_45infobNGFRxvuGcMeta?.alias || [],
    redirect: update_45user_45infobNGFRxvuGcMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/update-user-info.vue").then(m => m.default || m)
  },
  {
    name: user_45collectionZjH2tkquQDMeta?.name ?? "user-collection",
    path: user_45collectionZjH2tkquQDMeta?.path ?? "/user-collection",
    meta: user_45collectionZjH2tkquQDMeta || {},
    alias: user_45collectionZjH2tkquQDMeta?.alias || [],
    redirect: user_45collectionZjH2tkquQDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/user-collection.vue").then(m => m.default || m)
  },
  {
    name: userguidekh3RpuDimaMeta?.name ?? "userguide",
    path: userguidekh3RpuDimaMeta?.path ?? "/userguide",
    meta: userguidekh3RpuDimaMeta || {},
    alias: userguidekh3RpuDimaMeta?.alias || [],
    redirect: userguidekh3RpuDimaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/userguide.vue").then(m => m.default || m)
  },
  {
    name: utilitiesBXIgi23fvKMeta?.name ?? "utilities",
    path: utilitiesBXIgi23fvKMeta?.path ?? "/utilities",
    meta: utilitiesBXIgi23fvKMeta || {},
    alias: utilitiesBXIgi23fvKMeta?.alias || [],
    redirect: utilitiesBXIgi23fvKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/utilities.vue").then(m => m.default || m)
  }
]