import revive_payload_client_Rn0sTd6N7c from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7suIDXKZw1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uJtQNa2Avj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_XN9zp4XxiP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.16_vite@5.0.11_vue@3.5.3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import check_outdated_build_client_vxbPNpi5KL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_bmuebTnyxx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_jKO9bg7aEc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_fUEvfpaGmZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_vue3_Z04pqREhmH from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.4.4_typescript@5.0.2_vue@3.5.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import plugin_oSmnxajegB from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.0/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_client_B86mNmBton from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_5dGmXc9Bjr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_uFXO7QhtHq from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.2_@pinia+nuxt@0.4.4_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import api_QQzOodE9HA from "/opt/buildhome/repo/plugins/api.js";
import recaptcha_client_ElRG0N5AcO from "/opt/buildhome/repo/plugins/recaptcha.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
export default [
  revive_payload_client_Rn0sTd6N7c,
  unhead_7suIDXKZw1,
  router_uJtQNa2Avj,
  _0_siteConfig_XN9zp4XxiP,
  check_outdated_build_client_vxbPNpi5KL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bmuebTnyxx,
  nuxt_plugin_jKO9bg7aEc,
  plugin_fUEvfpaGmZ,
  plugin_vue3_Z04pqREhmH,
  plugin_oSmnxajegB,
  plugin_client_B86mNmBton,
  chunk_reload_client_5dGmXc9Bjr,
  plugin_uFXO7QhtHq,
  api_QQzOodE9HA,
  recaptcha_client_ElRG0N5AcO,
  sentry_client_shVUlIjFLk
]